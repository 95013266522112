import { Box, Card, CardContent, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { arrow_back, arrow_forward } from 'src/images';
import { call_icon, explore_icon, hand_raise_icon } from '../home/icons/inex';
import { GameLayout } from 'src/layout';
import { API_URLS } from 'src/constants/API_URLS';
import { Get } from 'src/actions';

function Question() {
  const navigate = useNavigate();
  const location = useLocation();
  const { question, userCateogory, allCategories, game_name } = location.state || {};

  const [time, setTime] = useState(0);
  const [isRunning, setIsRunning] = useState(true);
  const [isCallTimerVisible, setIsCallTimerVisible] = useState(false);
  const [callTime, setCallTime] = useState(60);
  const [isHandRaised, setIsHandRaised] = useState(false);

  useEffect(() => {
    const savedTime = parseInt(localStorage.getItem('timerTime'), 10) || 0;
    const savedIsRunning = localStorage.getItem('timerIsRunning');
    const savedIsCallTimerVisible = localStorage.getItem('isCallTimerVisible') === 'true';
    const savedCallTime = parseInt(localStorage.getItem('callTimerTime'), 10) || 60;

    setTime(savedTime);
    setIsRunning(savedIsRunning !== null ? savedIsRunning === 'true' : true);
    setIsCallTimerVisible(savedIsCallTimerVisible);
    setCallTime(savedCallTime);
  }, []);

  useEffect(() => {
    localStorage.setItem('timerTime', time);
    localStorage.setItem('timerIsRunning', isRunning);
    localStorage.setItem('isCallTimerVisible', isCallTimerVisible);
    localStorage.setItem('callTimerTime', callTime);

    return () => {
      localStorage.removeItem('timerTime');
      localStorage.removeItem('timerIsRunning');
      localStorage.removeItem('isCallTimerVisible');
      localStorage.removeItem('callTimerTime');
    };
  }, [time, isRunning, isCallTimerVisible, callTime]);

  useEffect(() => {
    let interval;
    if (isRunning) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime + 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isRunning]);

  useEffect(() => {
    let callInterval;
    if (isCallTimerVisible && callTime > 0) {
      callInterval = setInterval(() => {
        setCallTime((prevTime) => prevTime - 1);
      }, 1000);
    } else if (callTime === 0) {
      setIsCallTimerVisible(false);
      setCallTime(60);
      setIsRunning(true);
    }
    return () => clearInterval(callInterval);
  }, [isCallTimerVisible, callTime]);

  const handleCallClick = () => {
    setIsRunning(false);
    setIsCallTimerVisible(true);
  };

  const handlePauseResume = () => {
    setIsRunning(!isRunning);
  };

  const handleGetResults = () => {
    try {
      Get(
        {},
        API_URLS.GET_WINNER.replace(':id', allCategories[0]?.id),
        (resp) => {
          navigate('/congrats', { state: { result: resp?.data, allCategories, game_name } });
        },
        (error) => {}
      );
    } catch (error) {}
  };

  return (
    <>
      <GameLayout
        gameName={game_name}
        teamname={allCategories[0]?.team1_name}
        onBackToDashboard={() => {
          navigate('/team-score', { state: { userCategories: allCategories, game_name } });
        }}
        onExit={() => {
          navigate('/my-games');
        }}
        onGameOver={handleGetResults}
      />
      <Grid container spacing={2} sx={{ width: '95%', margin: 'auto' }}>
        <Grid item xs={12} md={8} lg={9}>
          <Card
            sx={{
              boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.2)',
              borderRadius: '20px',
              backgroundColor: '#ffffff',
              p: 3,
              height: '90%',
            }}
          >
            <CardContent>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', sm: 'row' },
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  gap: 2,
                  mb: 3,
                }}
              >
                <button
                  style={{
                    backgroundColor: '#6DCEF2',
                    borderRadius: '30px',
                    color: 'black',
                    width: '100%',
                    maxWidth: '150px',
                    height: '50px',
                    fontSize: '1.25rem',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '5px',
                  }}
                  onClick={handlePauseResume}
                >
                  <img src={arrow_back} alt="arrow_back" />
                  {`${new Date(time * 1000).toISOString().substr(14, 5)}`}
                  <img src={arrow_forward} alt="arrow forward" />
                </button>
                <button
                  style={{
                    background: '#000000',
                    width: '100%',
                    maxWidth: '150px',
                    height: '50px',
                    borderRadius: '30px',
                    color: 'white',
                    fontSize: '1.25rem',
                  }}
                >
                  {question?.score}: نقاط
                </button>
              </Box>

              <Box
                sx={{
                  textAlign: { xs: 'center', sm: 'right' },
                  px: { xs: 2, sm: 0 },
                  mb: 3,
                }}
              >
                <p
                  style={{
                    fontSize:
                      window.innerWidth > 350 && window.innerWidth < 550
                        ? '0.6rem'
                        : window.innerWidth > 550 && window.innerWidth < 1024
                        ? '0.9rem'
                        : '2.5rem',
                    fontFamily: 'Noto Kufi Arabic, sans-serif',
                  }}
                >
                  {question?.question}
                </p>
              </Box>

              <Box sx={{ textAlign: 'center', mb: 3 }}>
                <img
                  src={question?.question_img}
                  alt="Argentina"
                  style={{ width: '100%', maxWidth: '800px', height: 'auto' }}
                />
              </Box>

              <Box sx={{ textAlign: { xs: 'center', sm: 'right' } }}>
                <button
                  style={{
                    backgroundColor: '#6DCEF2',
                    width: '100%',
                    maxWidth: '150px',
                    height: '50px',
                    borderRadius: '30px',
                    color: 'black',
                    fontSize: '1.25rem',
                  }}
                  onClick={() =>
                    navigate('/answer', {
                      state: { answer: question, timeToAnswer: time, userCateogory, allCategories, game_name },
                    })
                  }
                >
                  الاجابة
                </button>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4} lg={3}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              height: '100%',
            }}
          >
            {!isCallTimerVisible ? (
              <>
                <p
                  style={{
                    fontSize: '27px',
                    fontFamily: 'cairo',
                    backgroundColor: '#000000',
                    padding: '1rem',
                    borderRadius: '30px',
                    color: '#ffffff',
                    textAlign: 'center',
                    width: '240px',
                    height: '40px',
                  }}
                >
                  {userCateogory?.team1_name || 'Team 1'}
                </p>
                <p
                  style={{
                    fontFamily: 'Noto Kufi Arabic, sans-serif',
                    fontWeight: 'bold',
                    fontSize: '2rem',
                    padding: '1rem',
                    textAlign: 'center',
                    color: '#000000',
                    borderRadius: '30px',
                  }}
                >
                  وسائل مساعدة
                </p>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '1rem',
                  }}
                >
                  {!isHandRaised && (
                    <>
                      <img
                        src={hand_raise_icon}
                        alt="hand_raise_icon"
                        height={70}
                        width={70}
                        style={{
                          border: '1px solid black',
                          borderRadius: '15%',
                        }}
                        onClick={() => setIsHandRaised(true)}
                      />
                      <img
                        src={call_icon}
                        alt="call_icon"
                        height={70}
                        width={70}
                        style={{
                          border: '1px solid black',
                          borderRadius: '15%',
                          cursor: 'pointer',
                        }}
                        onClick={handleCallClick}
                      />
                      <img
                        src={explore_icon}
                        alt="explore_icon"
                        height={70}
                        width={70}
                        style={{
                          border: '1px solid black',
                          borderRadius: '15%',
                        }}
                      />
                    </>
                  )}

                  {isHandRaised && (
                    <p
                      style={{
                        fontFamily: 'Noto Kufi Arabic, sans-serif',
                        fontWeight: 'bold',
                        fontSize: '1.5rem',
                        padding: '1rem',
                        textAlign: 'center',
                        color: '#000000',
                        borderRadius: '50px',
                        backgroundColor: '#6DCEF2',
                      }}
                    >
                      جاوب جوابين
                    </p>
                  )}
                </div>
              </>
            ) : (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  gap: '1rem',
                }}
              >
                <p
                  style={{
                    fontSize: '1rem',
                    fontFamily: 'Noto Kufi Arabic, sans-serif',
                    color: '#ffffff',
                    height: '100px',
                    width: '100px',
                    borderRadius: '50%',
                    backgroundColor: '#6DCEF2',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '1rem',
                  }}
                >
                  {`${callTime} seconds`}
                </p>
              </div>
            )}

            {!isCallTimerVisible ? (
              <>
                <p
                  style={{
                    fontSize: '27px',
                    fontFamily: 'cairo',
                    backgroundColor: '#000000',
                    padding: '1rem',
                    borderRadius: '30px',
                    color: '#ffffff',
                    textAlign: 'center',
                    width: '240px',
                    height: '40px',
                  }}
                >
                  {userCateogory?.team2_name || 'Team 2'}
                </p>
                <p
                  style={{
                    fontFamily: 'Noto Kufi Arabic, sans-serif',
                    fontWeight: 'bold',
                    fontSize: '2rem',
                    padding: '1rem',
                    textAlign: 'center',
                    color: '#000000',
                    borderRadius: '30px',
                  }}
                >
                  وسائل مساعدة
                </p>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '1rem',
                  }}
                >
                  {!isHandRaised && (
                    <>
                      <img
                        src={hand_raise_icon}
                        alt="hand_raise_icon"
                        height={70}
                        width={70}
                        style={{
                          border: '1px solid black',
                          borderRadius: '15%',
                        }}
                        onClick={() => setIsHandRaised(true)}
                      />
                      <img
                        src={call_icon}
                        alt="call_icon"
                        height={70}
                        width={70}
                        style={{
                          border: '1px solid black',
                          borderRadius: '15%',
                          cursor: 'pointer',
                        }}
                        onClick={handleCallClick}
                      />
                      <img
                        src={explore_icon}
                        alt="explore_icon"
                        height={70}
                        width={70}
                        style={{
                          border: '1px solid black',
                          borderRadius: '15%',
                        }}
                      />
                    </>
                  )}

                  {isHandRaised && (
                    <p
                      style={{
                        fontFamily: 'Noto Kufi Arabic, sans-serif',
                        fontWeight: 'bold',
                        fontSize: '1.5rem',
                        padding: '1rem',
                        textAlign: 'center',
                        color: '#000000',
                        borderRadius: '50px',
                        backgroundColor: '#6DCEF2',
                      }}
                    >
                      جاوب جوابين
                    </p>
                  )}
                </div>
              </>
            ) : (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  gap: '1rem',
                }}
              >
                <p>You have 1 min to call</p>
              </div>
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default Question;
