import { Card, CardContent, Grid } from '@mui/material';
import React from 'react';
import { Games } from './constants';
import GameCard from './GameCard';

function GamePackages() {
  return (
    <>
      <Card
        // make card borderless and remove shadow
        sx={{
          border: 'none',
          boxShadow: 'none',
        }}
      >
        <CardContent>
          <p
            style={{
              textAlign: 'center',
              fontSize: '3rem',
              fontWeight: 'bold',
              fontFamily: 'Noto Kufi Arabic',
            }}
          >
            باقات الألعاب
          </p>
          <p
            style={{
              textAlign: 'center',
              fontSize: '2rem',

              fontFamily: 'Noto Kufi Arabic',
            }}
          >
            لكل مستخدم لعبة واحدة مجانية يمكنك من خلالها تجربة الفئات الموجودة
          </p>
          <br />
          <Grid container spacing={2}>
            {Games.map((game) => (
              <Grid item xs={12} sm={6} md={3} key={game.title}>
                <GameCard {...game} />
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

export default GamePackages;
