import { Box, Card, CardMedia } from '@mui/material';
import React from 'react';

function GameCard({ src, title, price, discount }) {
  return (
    <Card
      style={{
        position: 'relative', // Allows positioning of the discount label
        width: '100%',
        maxWidth: '400px', // Sets a maximum width for larger screens
        margin: 'auto', // Centers the card on larger screens
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
      }}
    >
      {/* Discount Label */}
      {discount > 0 && (
        <div
          style={{
            position: 'absolute',
            top: '40px',
            left: '-50px',
            fontFamily: 'monospace',
            backgroundColor: 'red',
            width: '160px',
            color: 'white',
            textAlign: 'center',
            padding: '5px 20px',
            transform: 'rotate(-45deg)', // Rotates the label diagonally
            fontSize: '1rem',
            fontWeight: 'bold',
            zIndex: 10,
            pointerEvents: 'none', // Prevents interactions with the label
          }}
        >
          {discount} % OFF
        </div>
      )}
      <CardMedia
        component="img"
        image={src}
        alt={title}
        style={{
          width: '100%', // Ensures the image takes full width
          height: 'auto', // Maintains aspect ratio
        }}
      />
      <p
        style={{
          textAlign: 'center',
          fontFamily: 'Noto Kufi Arabic',
          fontSize: '1.2rem', // Base font size
          margin: '0.5rem 0',
        }}
      >
        {title}
      </p>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '1rem',
        }}
      >
        <button
          style={{
            background: 'yellow',
            color: 'black',
            width: '100%', // Full width on smaller screens
            maxWidth: '120px', // Restrict width for larger screens
            height: '40px',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            fontFamily: 'Noto Kufi Arabic',
            fontWeight: 'bold',
            fontSize: '1rem',
            marginBottom: '0.5rem', // Spacing between elements
          }}
        >
          شراء
        </button>
        <p
          style={{
            textAlign: 'center',
            fontFamily: 'Noto Kufi Arabic',
            fontSize: '1rem',
            margin: '0.5rem 0',
          }}
        >
          &nbsp;{price} د.ك
        </p>
      </Box>
    </Card>
  );
}

export default GameCard;
