import { Card, CardContent, Grid } from '@mui/material';
import React from 'react';
import { helpingData } from './constants';
import AssistanceCard from './AssistanceCard';
function Assistance() {
  return (
    <Card
      style={{
        backgroundColor: '#6DCEF2',
        padding: '20px',
        margin: '20px',
        borderRadius: '10px',
        boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.2)',
      }}
    >
      <CardContent>
        <p
          style={{
            textAlign: 'center',
            fontFamily: 'Noto Kufi Arabic',
            fontSize: '2rem',
            fontWeight: 'bold',
            color: 'white',
          }}
        >
          وسائل المساعدة
        </p>
        <Grid container spacing={2}>
          {helpingData.map((data) => (
            <Grid item xs={12} sm={6} md={4}>
              <AssistanceCard
                description={data.description}
                title={data.title}
                icon={data.icon}
                bgColor={data?.bgColor}
              />
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
}

export default Assistance;
