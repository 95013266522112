import { ArrowBack } from '@mui/icons-material';
import { Box, Card, CardContent, Grid, IconButton } from '@mui/material';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { call_icon, explore_icon, hand_raise_icon } from '../home/icons/inex';
import { GameLayout } from 'src/layout';
import { API_URLS } from 'src/constants/API_URLS';
import { Get } from 'src/actions';

function Answer() {
  const navigate = useNavigate();
  const location = useLocation();
  const { answer, userCateogory, allCategories, game_name } = location?.state || {}; // Retrieve the state

  console.log('jsdfdsfdsf', allCategories);

  const handleGetResults = () => {
    try {
      Get(
        {},
        API_URLS.GET_WINNER.replace(':id', allCategories[0]?.id),
        (resp) => {
          navigate('/congrats', { state: { result: resp?.data, allCategories: allCategories, game_name: game_name } });
        },
        (error) => {}
      );
    } catch (error) {}
  };

  return (
    <>
      <GameLayout
        gameName={game_name}
        teamname={allCategories[0]?.team1_name}
        onBackToDashboard={() => {
          navigate('/team-score', { state: { userCategories: allCategories, game_name: game_name } });
        }}
        onExit={() => {
          navigate('/my-games');
        }}
        onGameOver={() => {
          handleGetResults();
        }}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} md={9}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: '10px',
              width: '80%',
              margin: 'auto',
              mb: '-40px', // Margin below buttons
            }}
          >
            <button
              style={{
                backgroundColor: '#6DCEF2',
                fontFamily: 'Noto Kufi Arabic, sans-serif',
                width: '100%',
                maxWidth: '150px',
                height: '50px',
                borderRadius: '30px',
                color: 'black',
                fontSize: '1.25rem',
              }}
            >
              {answer?.score}: نقاط
            </button>
            <button
              style={{
                backgroundColor: 'red',
                borderRadius: '30px',
                color: 'white',
                width: '100%',
                maxWidth: '200px',
                height: '50px',
                fontSize: '1.25rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '5px',
              }}
            >
              Report Now
            </button>
          </Box>

          <Card
            sx={{
              borderRadius: '30px',
              p: '20px',
              boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
              mt: '20px',
            }}
          >
            <CardContent>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                  px: { xs: '10px', sm: '0px' },
                  mb: '20px',
                }}
              >
                <p
                  style={{
                    fontSize: '1.25rem',
                    fontFamily: 'Noto Kufi Arabic, sans-serif',
                    color: '#000000',
                    fontWeight: 'bold',
                  }}
                >
                  {answer?.answer}
                </p>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  mb: '20px',
                }}
              >
                <img
                  src={answer?.answer_img}
                  alt="Answer"
                  style={{
                    width: '100%',
                    maxWidth: '800px',
                    height: 'auto',
                    borderRadius: '10px',
                  }}
                />
              </Box>
            </CardContent>
          </Card>

          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: '10px',
              width: '80%',
              margin: 'auto',
              mt: '-40px',
            }}
          >
            <button
              style={{
                backgroundColor: 'black',
                fontFamily: 'Noto Kufi Arabic, sans-serif',
                width: '100%',
                maxWidth: '150px',
                height: '50px',
                borderRadius: '10px',
                color: 'white',
                fontSize: '1.25rem',
              }}
              onClick={() =>
                navigate('/result', {
                  state: {
                    question: answer,
                    userCateogory: userCateogory,
                    allCategories: allCategories,
                    game_name: game_name,
                  },
                })
              }
            >
              ؟ من أجاب{' '}
            </button>
            {/* <p
              style={{
                backgroundColor: '#6DCEF2',
                fontFamily: 'Noto Kufi Arabic, sans-serif',
                borderRadius: '10px',
                color: 'black',
                fontSize: '1.25rem',
                padding: '10px',
                textAlign: 'center',
              }}
            >
              <img src={pause} alt="pause" height={15} width={15} />
              &nbsp;
              {'00:' + (timeToAnswer || 0)} &nbsp;
              <img src={clock} alt="clock" height={15} width={15} />
            </p> */}
            <button
              style={{
                backgroundColor: '#6DCEF2',
                fontFamily: 'Noto Kufi Arabic, sans-serif',
                width: '100%',
                maxWidth: '300px',
                height: '50px',
                borderRadius: '10px',
                color: 'black',
                fontSize: '1.25rem',
              }}
              onClick={() =>
                navigate('/question', {
                  state: {
                    question: answer,
                    userCateogory: userCateogory,
                    allCategories: allCategories,
                    game_name: game_name,
                  },
                })
              }
            >
              <IconButton>
                <ArrowBack />
              </IconButton>{' '}
              العودة إلى السؤال
            </button>
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              height: '100%',
            }}
          >
            <p
              style={{
                fontSize: '27px',
                fontFamily: 'Cairo',
                backgroundColor: '#000000',
                padding: '1rem',
                borderRadius: '30px',
                color: '#ffffff',
                textAlign: 'center',
                width: '240px',
                height: '40px',
              }}
            >
              {userCateogory?.team1_name || 'Team 1'}
            </p>
            <p
              style={{
                fontFamily: 'Noto Kufi Arabic, sans-serif',
                fontWeight: 'bold',
                fontSize: '2rem',
                padding: '1rem',
                textAlign: 'center',
                color: '#000000',
                borderRadius: '30px',
              }}
            >
              وسائل مساعدة
            </p>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '1rem',
              }}
            >
              <img
                src={hand_raise_icon}
                alt="hand_raise_icon"
                height={70}
                width={70}
                style={{
                  border: '1px solid black',
                  borderRadius: '15%',
                }}
              />
              <img
                src={call_icon}
                alt="call_icon"
                height={70}
                width={70}
                style={{
                  border: '1px solid black',
                  borderRadius: '15%',
                }}
              />
              <img
                src={explore_icon}
                alt="explore_icon"
                height={70}
                width={70}
                style={{
                  border: '1px solid black',
                  borderRadius: '15%',
                }}
              />
            </Box>
            <br />
            <p
              style={{
                fontSize: '27px',
                fontFamily: 'Cairo',
                backgroundColor: '#000000',
                padding: '1rem',
                borderRadius: '30px',
                color: '#ffffff',
                textAlign: 'center',
                width: '240px',
                height: '40px',
              }}
            >
              {userCateogory?.team2_name || 'Team 2'}
            </p>
            <p
              style={{
                fontFamily: 'Noto Kufi Arabic, sans-serif',
                fontWeight: 'bold',
                fontSize: '2rem',
                padding: '1rem',
                textAlign: 'center',
                color: '#000000',
                borderRadius: '30px',
              }}
            >
              وسائل مساعدة
            </p>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '1rem',
              }}
            >
              <img
                src={hand_raise_icon}
                alt="hand_raise_icon"
                height={70}
                width={70}
                style={{
                  border: '1px solid black',
                  borderRadius: '15%',
                }}
              />
              <img
                src={call_icon}
                alt="call_icon"
                height={70}
                width={70}
                style={{
                  border: '1px solid black',
                  borderRadius: '15%',
                }}
              />
              <img
                src={explore_icon}
                alt="explore_icon"
                height={70}
                width={70}
                style={{
                  border: '1px solid black',
                  borderRadius: '15%',
                }}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default Answer;
