import { Box, Button, Card, CardContent } from '@mui/material';
import React from 'react';
import { intro_logo } from 'src/images';

// import { useNavigate } from 'react-router-dom';
function Introduction({ onBrowseClick, onCreateClick }) {
  // const navigate = useNavigate();
  return (
    <Card
      sx={{
        // show card gradient background from DDE84A to 6FCEED
        backgroundImage: 'linear-gradient(315deg, #6fceed 0%, #dde84a 74%)',
        padding: '1rem',
        color: 'white',
        textAlign: 'center',
        fontSize: '1.5rem',
        fontWeight: 'bold',
        fontFamily: 'Nutino Kufi Arabic, sans-serif',
        borderRadius: '30px',
      }}
    >
      <CardContent>
        <img
          src={intro_logo}
          alt="home"
          style={{
            width: ' 279.04px',
            height: '314px',
            top: '294px',
            left: '725px',
            gap: '0px',
            opacity: '0px',
          }}
        />
        <p
          style={{
            fontSize: '2rem',
            fontWeight: 'bold',
            fontFamily: 'Noto Kufi Arabic, sans-serif',
          }}
        >
          الجواب عليك، و السؤال علينا
        </p>
        <p
          style={{
            fontSize: '1.5rem',
            fontFamily: 'Noto Kufi Arabic, sans-serif',
          }}
        >
          ٦ فئات، ٣٦ سؤال، و معاهم ٣ وسائل مساعدة
        </p>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            gap: '1rem',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            sx={{
              backgroundColor: 'black',
              color: 'yellow',
              borderRadius: '50px',
              width: '200px',
              fontFamily: 'Noto Kufi Arabic, sans-serif',
              fontSize: '1.5rem',
            }}
            onClick={() => onBrowseClick()}
          >
            {/* button for Browse games */}
            تصفح الألعاب
          </Button>
          <Button
            sx={{
              backgroundColor: 'yellow',
              color: 'black',
              borderRadius: '50px',
              width: '200px',
              fontFamily: 'Noto Kufi Arabic, sans-serif',
              fontSize: '1.5rem',
            }}
            onClick={() => onCreateClick()}
          >
            {/* Button for create games */}
            إنشاء لعبة
          </Button>
          <Button
            sx={{
              backgroundColor: 'yellow',
              color: 'black',
              borderRadius: '50px',
              width: '200px',
              fontFamily: 'Noto Kufi Arabic, sans-serif',
              fontSize: '1.5rem',
            }}
            onClick={() => onCreateClick()}
          >
            {/* Button for create games */}
            واجهة الأطفال
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
}

export default Introduction;