import { Card, CardContent, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CategoryCard from './CategoryCard';
import { NotFoundAvatar } from 'src/images';
import { info_icon } from './icons';
import { useSnackbar } from 'notistack';

function Categories({ categories, onSelectedCategories }) {
  const [selectedCategoryIds, setSelectedCategoryIds] = useState([]);
  // const [selected, setSelected] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleCategorySelection = (id) => {
    // Check if the category is already selected
    if (selectedCategoryIds.includes(id)) {
      // remove the selected category ID from the array
      setSelectedCategoryIds(selectedCategoryIds.filter((categoryId) => categoryId !== id));
      return;
    }
    // Check if the maximum number of selected categories is reached
    if (selectedCategoryIds.length >= 6) {
      enqueueSnackbar('لا يمكنك اختيار اكثر من 6 فئات', { variant: 'error' });
      return;
    }
    // Add the selected category ID to the array
    setSelectedCategoryIds([...selectedCategoryIds, id]);
  };

  useEffect(() => {
    onSelectedCategories(selectedCategoryIds);
  }, [onSelectedCategories, selectedCategoryIds]);
  return (
    <>
      {categories.length > 0 && (
        <Card
          style={{
            border: 'none',
            boxShadow: 'none',
            background: 'transparent',
            borderRadius: '20px',
            padding: '20px',
            height: '100%',
          }}
        >
          <CardContent>
            <p
              style={{
                textAlign: 'center',
                fontFamily: 'Noto Kufi Arabic, sans-serif',
                fontSize: '4rem',
                fontWeight: 'bold',
              }}
            >
              الفئات الدائمة
            </p>
            {/* show categories 5 per row with same size categories_data data from constants*/}
            <Grid container style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              alignContent: 'center',
              textAlign: 'center',
              width: '90%',
              margin: 'auto',
            }}>
              {categories?.map((category, index) => (
                <Grid
                  item
                  xs={6} // 2 cards per row on extra-small screens (e.g., mobile)
                  sm={4} // 3 cards per row on small screens
                  md={3} // 4 cards per row on medium screens (tablets)
                  lg={2} // 6 cards per row on large screens (desktops)
                  xl={1.5} // 8 cards per row on extra-large screens
                  key={index}
                  style={{ display: 'flex', justifyContent: 'center' }} // Ensures proper card alignment
                  onClick={() => handleCategorySelection(category?.id)}
                >
                  <CategoryCard
                    info={category?.info}
                    avatar={category?.avatar}
                    title={category.name}
                    icon={info_icon}
                    button_text={category?.name}
                    selected={selectedCategoryIds.includes(category?.id)}
                  />
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>
      )}
      {categories.length === 0 && (
        <CategoryCard avatar={NotFoundAvatar} title="الفئات الدائمة" icon={info_icon} button_text="لا يوجد فئات" />
      )}
    </>
  );
}

export default Categories;
