import { GameLayout } from 'src/layout';
import Footer from './components/footer';
// import Header from "./components/header";
import Item from './components/item';
import './team-stats.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { Get } from 'src/actions';
import { API_URLS } from 'src/constants/API_URLS';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { AddContained, MinusContained } from 'src/images';
import { call_icon, explore_icon, hand_raise_icon } from 'src/sections/home/icons/inex';
import { Ad } from '..';
const TeamStats = () => {
  // const getScreenHeight = () => {
  //   return window.innerHeight;
  // };
  const navigate = useNavigate();
  // const [userCategories, setUserCategories] = useState([]);
  const userId = localStorage.getItem('userId');
  const location = useLocation();
  const [teamScore, setTeamScore] = useState({
    team1_score: 0,
    team2_score: 0,
  });
  const { userCategories, game_name } = location.state || {};
  // const [screenHeight, setScreenHeight] = useState(getScreenHeight());

  console.log('jhsdfsdfdsfsdf', game_name);

  const getScore = useCallback(() => {
    try {
      Get(
        {},
        API_URLS.GET_SCORE.replace(':userId', userId)
          .replace(':team1_name', userCategories[0]?.team1_name)
          .replace(':team2_name', userCategories[0]?.team2_name),
        (response) => {
          console.log('response', response?.data);
          setTeamScore({
            team1_score: response?.data?.team1_score,
            team2_score: response?.data?.team2_score,
          });
        },
        (error) => {
          console.log('error', error);
        }
      );
    } catch (error) {
      console.log('error', error);
    }
  }, [userId, userCategories]);

  useEffect(() => {
    getScore();
  }, [getScore]);
  console.log('userCategories', teamScore);

  // const getAllCategories = useCallback(() => {
  //   try {
  //     Get(
  //       {},
  //       API_URLS.GET_USER_CATEGORIES.replace(':id', userId),
  //       (response) => {
  //         // setUserCategories(response.data);
  //       },
  //       (error) => {
  //         console.log('error', error);
  //       }
  //     );
  //   } catch (error) {
  //     console.log('error', error);
  //   }
  // }, [userId]);

  // useEffect(() => {
  //   getAllCategories();
  // }, [getAllCategories]);

  const handleGetResults = () => {
    try {
      Get(
        {},
        API_URLS.GET_WINNER.replace(':id', userCategories[0]?.id),
        (resp) => {
          navigate('/congrats', { state: { result: resp?.data, allCategories: userCategories, game_name: game_name } });
        },
        (error) => {}
      );
    } catch (error) {}
  };

  // Responsive height detection for orientation

  // useEffect(() => {
  //   const handleResize = () => {
  //     setScreenHeight(getScreenHeight());
  //   };

  //   window.addEventListener('resize', handleResize);

  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //   };
  // }, []);

  return (
    <div className="app-container">
      <GameLayout
        gameName={game_name}
        teamname={userCategories[0]?.team1_name}
        onBackToDashboard={() => {
          console.log('already on dashboard');
        }}
        onExit={() => {
          navigate('/my-games');
        }}
        onGameOver={() => {
          handleGetResults();
        }}
      />
      {userCategories?.length === 0 && <p>No Games Found</p>}
      <section>
        {userCategories?.map((item) => (
          <Item
            key={item}
            className="grid-item"
            userCateogory={item}
            allCategories={userCategories}
            game_name={game_name}
          />
        ))}
      </section>

      <Card
        // className="footer"
        sx={{
          top: 'auto',
          bottom: 0,
          left: 0,
          right: 0,
          height: '150px',
          width: '90%',
          margin: 'auto',
        }}
      >
        <CardContent>
          <Grid container spacing={2}>
            <Grid xs={6} sm={6} md={6} lg={4} order={{ xs: 1, sm: 1, md: 1, lg: 1 }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'row', md: 'row' },
                  gap: 1,
                  width: '90%',
                  margin: 'auto',
                  justifyContent: { xs: 'center', md: 'flex-start' },
                  alignItems: { xs: 'center', md: 'center' },
                  marginTop: '15px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-between',
                    alignItems: 'flex-between',
                    gap: 0.5,
                    // backgroundColor: 'grey', // Example background color
                    width: { xs: '50%', md: '100%' },
                  }}
                >
                  <Typography
                    sx={{
                      backgroundColor: 'black',
                      color: 'white',
                      padding: { xs: '5px 0px' },
                      borderRadius: '5px',
                      textAlign: 'center',
                      // '&:hover': { backgroundColor: 'darkgray' },
                      width: '100%',
                    }}
                  >
                    {userCategories[0]?.team1_name}
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      // alignItems: 'center',
                      width: '100%',
                      // height: '100%',
                      color: '#000000',
                      border: '1px solid #000000',
                      borderRadius: '5px',
                    }}
                  >
                    <img
                      src={AddContained}
                      alt="add"
                      style={{
                        background: '#000000',
                        width:
                          window.innerWidth > 320 && window.innerWidth < 480
                            ? '25px'
                            : window.innerWidth > 481 && window.innerWidth < 768
                            ? '30px'
                            : window.innerWidth > 769 && window.innerWidth < 1024
                            ? '35px'
                            : window.innerWidth > 1025 && window.innerWidth < 1440
                            ? '35px'
                            : '35px',
                        height:
                          window.innerWidth > 320 && window.innerWidth < 480
                            ? '25px'
                            : window.innerWidth > 481 && window.innerWidth < 768
                            ? '30px'
                            : window.innerWidth > 769 && window.innerWidth < 1024
                            ? '35px'
                            : window.innerWidth > 1025 && window.innerWidth < 1440
                            ? '35px'
                            : '35px',
                        borderRadius: '5px',
                        margin: '2px',
                      }}
                    />
                    <Typography>{teamScore?.team1_score || 0}</Typography>
                    <img
                      src={MinusContained}
                      alt="minus"
                      style={{
                        background: '#000000',
                        width:
                          window.innerWidth > 320 && window.innerWidth < 480
                            ? '25px'
                            : window.innerWidth > 481 && window.innerWidth < 768
                            ? '30px'
                            : window.innerWidth > 769 && window.innerWidth < 1024
                            ? '35px'
                            : window.innerWidth > 1025 && window.innerWidth < 1440
                            ? '35px'
                            : '35px',
                        height:
                          window.innerWidth > 320 && window.innerWidth < 480
                            ? '25px'
                            : window.innerWidth > 481 && window.innerWidth < 768
                            ? '30px'
                            : window.innerWidth > 769 && window.innerWidth < 1024
                            ? '35px'
                            : window.innerWidth > 1025 && window.innerWidth < 1440
                            ? '35px'
                            : '35px',
                        borderRadius: '5px',
                        margin: '2px',
                      }}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    justifyContent: { xs: 'center', md: 'center', lg: 'flex-between' },
                    alignItems: { xs: 'center', md: 'center', lg: 'flex-between' },
                    width: '100%',
                    // backgroundColor: 'blue',
                  }}
                >
                  <Typography
                    sx={{
                      width: '100%',
                      textAlign: { xs: 'center', md: 'center', lg: 'center' },
                      margin: 'auto',
                      border: 'none',
                      borderRadius: '15px',
                      // padding: '5px 10px',
                      color: '#000000',
                      fontFamily: 'Noto Kufi Arabic, sans-serif',
                      fontWeight: 'bold',
                      fontSize:
                        window.innerWidth > 320 && window.innerWidth < 480
                          ? '0.6rem'
                          : window.innerWidth > 481 && window.innerWidth < 768
                          ? '0.8rem'
                          : window.innerWidth > 769 && window.innerWidth < 1024
                          ? '1rem'
                          : window.innerWidth > 1025 && window.innerWidth < 1440
                          ? '1.2rem'
                          : '1.5rem',
                    }}
                  >
                    وسائل مساعدة
                  </Typography>
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: '10px',
                      // marginBottom: '30px',
                      color: '#000000',
                      borderRadius: '5px',
                    }}
                  >
                    <img
                      src={explore_icon}
                      alt="explore"
                      style={{
                        background: '#FFFFFF',
                        width:
                          window.innerWidth > 320 && window.innerWidth < 480
                            ? '25px'
                            : window.innerWidth > 481 && window.innerWidth < 768
                            ? '30px'
                            : window.innerWidth > 769 && window.innerWidth < 1024
                            ? '35px'
                            : window.innerWidth > 1025 && window.innerWidth < 1440
                            ? '35px'
                            : '35px',
                        height:
                          window.innerWidth > 320 && window.innerWidth < 480
                            ? '25px'
                            : window.innerWidth > 481 && window.innerWidth < 768
                            ? '30px'
                            : window.innerWidth > 769 && window.innerWidth < 1024
                            ? '35px'
                            : window.innerWidth > 1025 && window.innerWidth < 1440
                            ? '35px'
                            : '35px',
                        borderRadius: '5px',
                        padding: '2px',
                        border: '1px solid #000000',
                      }}
                    />
                    <img
                      src={call_icon}
                      alt="call"
                      style={{
                        background: '#FFFFFF',
                        width:
                          window.innerWidth > 320 && window.innerWidth < 480
                            ? '25px'
                            : window.innerWidth > 481 && window.innerWidth < 768
                            ? '30px'
                            : window.innerWidth > 769 && window.innerWidth < 1024
                            ? '35px'
                            : window.innerWidth > 1025 && window.innerWidth < 1440
                            ? '35px'
                            : '35px',
                        height:
                          window.innerWidth > 320 && window.innerWidth < 480
                            ? '25px'
                            : window.innerWidth > 481 && window.innerWidth < 768
                            ? '30px'
                            : window.innerWidth > 769 && window.innerWidth < 1024
                            ? '35px'
                            : window.innerWidth > 1025 && window.innerWidth < 1440
                            ? '35px'
                            : '35px',
                        borderRadius: '5px',
                        padding: '2px',
                        border: '1px solid #000000',
                      }}
                    />
                    <img
                      src={hand_raise_icon}
                      alt="hand raise"
                      style={{
                        background: '#FFFFFF',
                        width:
                          window.innerWidth > 320 && window.innerWidth < 480
                            ? '25px'
                            : window.innerWidth > 481 && window.innerWidth < 768
                            ? '30px'
                            : window.innerWidth > 769 && window.innerWidth < 1024
                            ? '35px'
                            : window.innerWidth > 1025 && window.innerWidth < 1440
                            ? '35px'
                            : '35px',
                        height:
                          window.innerWidth > 320 && window.innerWidth < 480
                            ? '25px'
                            : window.innerWidth > 481 && window.innerWidth < 768
                            ? '30px'
                            : window.innerWidth > 769 && window.innerWidth < 1024
                            ? '35px'
                            : window.innerWidth > 1025 && window.innerWidth < 1440
                            ? '35px'
                            : '35px',
                        borderRadius: '5px',
                        padding: '2px',
                        border: '1px solid #000000',
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid xs={12} sm={12} md={12} lg={4} order={{ xs: 3, sm: 3, md: 3, lg: 2 }}>
              <Box
                sx={{
                  display: { xs: 'none', sm: 'none', md: 'none', lg: 'flex' },
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: { xs: '70%', sm: '70%', md: '70%', lg: '80%' },
                  margin: 'auto',
                  marginTop: '10px',
                  marginBottom: '15px',
                }}
              >
                <img
                  src={Ad}
                  alt="Adniyar"
                  style={{
                    height: '130px',
                    // window.innerWidth > 320 && window.innerWidth < 480
                    //   ? '50px'
                    //   : window.innerWidth > 481 && window.innerWidth < 768
                    //   ? '70px'
                    //   : window.innerWidth > 769 && window.innerWidth < 1024
                    //   ? '100px'
                    //   : window.innerWidth > 1025 && window.innerWidth < 1440
                    //   ? '80px'
                    //   : '100px',
                    width: '80%',
                    // objectFit: 'cover',
                    margin: 'auto',
                    // cursor: 'pointer',
                    // width: 'auto',
                    // maxWidth: 'auto',
                    // height: { xs: '120px', md: 'auto' },
                  }}
                  // className="central-image"
                />
              </Box>
              <br />
            </Grid>
            <Grid xs={6} sm={6} md={6} lg={4} order={{ xs: 2, sm: 2, md: 2, lg: 3 }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'row', md: 'row' },
                  gap: 1,
                  width: '90%',
                  margin: 'auto',
                  justifyContent: { xs: 'center', md: 'flex-start' },
                  alignItems: { xs: 'center', md: 'center' },
                  marginTop: '15px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    justifyContent: { xs: 'center', md: 'center', lg: 'flex-between' },
                    alignItems: { xs: 'center', md: 'center', lg: 'flex-between' },
                    width: '100%',
                    // backgroundColor: 'blue',
                  }}
                >
                  <Typography
                    sx={{
                      width: '100%',
                      textAlign: { xs: 'center', md: 'center', lg: 'right' },
                      margin: 'auto',
                      border: 'none',
                      borderRadius: '15px',
                      // padding: '5px 10px',
                      color: '#000000',
                      fontFamily: 'Noto Kufi Arabic, sans-serif',
                      fontWeight: 'bold',
                      fontSize:
                        window.innerWidth > 320 && window.innerWidth < 480
                          ? '0.5rem'
                          : window.innerWidth > 481 && window.innerWidth < 768
                          ? '0.8rem'
                          : window.innerWidth > 769 && window.innerWidth < 1024
                          ? '1rem'
                          : window.innerWidth > 1025 && window.innerWidth < 1440
                          ? '1.2rem'
                          : '1.5rem',
                    }}
                  >
                    وسائل مساعدة
                  </Typography>
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: '10px',
                      // marginBottom: '30px',
                      color: '#000000',
                      borderRadius: '5px',
                    }}
                  >
                    <img
                      src={explore_icon}
                      alt="explore"
                      style={{
                        background: '#FFFFFF',
                        width:
                          window.innerWidth > 320 && window.innerWidth < 480
                            ? '25px'
                            : window.innerWidth > 481 && window.innerWidth < 768
                            ? '30px'
                            : window.innerWidth > 769 && window.innerWidth < 1024
                            ? '35px'
                            : window.innerWidth > 1025 && window.innerWidth < 1440
                            ? '35px'
                            : '35px',
                        height:
                          window.innerWidth > 320 && window.innerWidth < 480
                            ? '25px'
                            : window.innerWidth > 481 && window.innerWidth < 768
                            ? '30px'
                            : window.innerWidth > 769 && window.innerWidth < 1024
                            ? '35px'
                            : window.innerWidth > 1025 && window.innerWidth < 1440
                            ? '35px'
                            : '35px',
                        borderRadius: '5px',
                        padding: '2px',
                        border: '1px solid #000000',
                      }}
                    />
                    <img
                      src={call_icon}
                      alt="call"
                      style={{
                        background: '#FFFFFF',
                        width:
                          window.innerWidth > 320 && window.innerWidth < 480
                            ? '25px'
                            : window.innerWidth > 481 && window.innerWidth < 768
                            ? '30px'
                            : window.innerWidth > 769 && window.innerWidth < 1024
                            ? '35px'
                            : window.innerWidth > 1025 && window.innerWidth < 1440
                            ? '35px'
                            : '35px',
                        height:
                          window.innerWidth > 320 && window.innerWidth < 480
                            ? '25px'
                            : window.innerWidth > 481 && window.innerWidth < 768
                            ? '30px'
                            : window.innerWidth > 769 && window.innerWidth < 1024
                            ? '35px'
                            : window.innerWidth > 1025 && window.innerWidth < 1440
                            ? '35px'
                            : '35px',
                        borderRadius: '5px',
                        padding: '2px',
                        border: '1px solid #000000',
                      }}
                    />
                    <img
                      src={hand_raise_icon}
                      alt="hand raise"
                      style={{
                        background: '#FFFFFF',
                        width:
                          window.innerWidth > 320 && window.innerWidth < 480
                            ? '25px'
                            : window.innerWidth > 481 && window.innerWidth < 768
                            ? '30px'
                            : window.innerWidth > 769 && window.innerWidth < 1024
                            ? '35px'
                            : window.innerWidth > 1025 && window.innerWidth < 1440
                            ? '35px'
                            : '35px',
                        height:
                          window.innerWidth > 320 && window.innerWidth < 480
                            ? '25px'
                            : window.innerWidth > 481 && window.innerWidth < 768
                            ? '30px'
                            : window.innerWidth > 769 && window.innerWidth < 1024
                            ? '35px'
                            : window.innerWidth > 1025 && window.innerWidth < 1440
                            ? '35px'
                            : '35px',
                        borderRadius: '5px',
                        padding: '2px',
                        border: '1px solid #000000',
                      }}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-between',
                    alignItems: 'flex-between',
                    gap: 0.5,
                    // backgroundColor: 'grey', // Example background color
                    width: { xs: '50%', md: '100%' },
                  }}
                >
                  <Typography
                    sx={{
                      backgroundColor: 'black',
                      color: 'white',
                      padding: { xs: '5px 0px' },
                      borderRadius: '5px',
                      textAlign: 'center',
                      // '&:hover': { backgroundColor: 'darkgray' },
                      width: '100%',
                    }}
                  >
                    {userCategories[0]?.team2_name}
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      // alignItems: 'center',
                      width: '100%',
                      // height: '100%',
                      color: '#000000',
                      border: '1px solid #000000',
                      borderRadius: '5px',
                    }}
                  >
                    <img
                      src={AddContained}
                      alt="add"
                      style={{
                        background: '#000000',
                        width:
                          window.innerWidth > 320 && window.innerWidth < 480
                            ? '25px'
                            : window.innerWidth > 481 && window.innerWidth < 768
                            ? '30px'
                            : window.innerWidth > 769 && window.innerWidth < 1024
                            ? '35px'
                            : window.innerWidth > 1025 && window.innerWidth < 1440
                            ? '35px'
                            : '35px',
                        height:
                          window.innerWidth > 320 && window.innerWidth < 480
                            ? '25px'
                            : window.innerWidth > 481 && window.innerWidth < 768
                            ? '30px'
                            : window.innerWidth > 769 && window.innerWidth < 1024
                            ? '35px'
                            : window.innerWidth > 1025 && window.innerWidth < 1440
                            ? '35px'
                            : '35px',
                        borderRadius: '5px',
                        margin: '2px',
                      }}
                    />
                    <Typography>{teamScore?.team2_score || 0}</Typography>
                    <img
                      src={MinusContained}
                      alt="minus"
                      style={{
                        background: '#000000',
                        width:
                          window.innerWidth > 320 && window.innerWidth < 480
                            ? '25px'
                            : window.innerWidth > 481 && window.innerWidth < 768
                            ? '30px'
                            : window.innerWidth > 769 && window.innerWidth < 1024
                            ? '35px'
                            : window.innerWidth > 1025 && window.innerWidth < 1440
                            ? '35px'
                            : '35px',
                        height:
                          window.innerWidth > 320 && window.innerWidth < 480
                            ? '25px'
                            : window.innerWidth > 481 && window.innerWidth < 768
                            ? '30px'
                            : window.innerWidth > 769 && window.innerWidth < 1024
                            ? '35px'
                            : window.innerWidth > 1025 && window.innerWidth < 1440
                            ? '35px'
                            : '35px',
                        borderRadius: '5px',
                        margin: '2px',
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

export default TeamStats;
