import "./App.css";
import Router from "./routes";
import "@fortawesome/fontawesome-free/css/all.min.css";
function App() {
  return (
    <>
      <Router />
    </>
  );
}

export default App;