import { Grid } from '@mui/material';
import React from 'react';
import { Games } from './images';

function AboutRakz() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={6}>
        <img
          src={Games}
          alt="games"
          style={{
            width: '100%',
            height: 'auto',
            maxWidth: '642px',
          }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          // gap: '0rem',
        }}
      >
        <h2
          style={{
            fontSize: '2rem',
            fontFamily: 'Noto Kufi Arabic, sans-serif',
            textAlign: 'right',
          }}
        >
          نبذة عن رَكز
        </h2>
        <p
          style={{
            fontSize: '2rem',
            fontFamily: 'Noto Kufi Arabic, sans-serif',
            textAlign: 'right',
          }}
        >
          لعبة جماعية ممتعة، تحتوي على ٦ فئات مختلفة و ٣٦ سؤال يختبر معلوماتكم، و عشان نضيف الحماس لليمعة ضفنا ٣ وسائل
          مساعدة لكل فريق، اختاروهم بعناية
        </p>

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          sx={{
            width: { xs: '50%', sm: '80%', md: '70%' }, // Responsive width
          }}
        >
          {['ترفيه', 'متعة', 'ذكاء', 'تحدي'].map((text, index) => (
            <React.Fragment key={index}>
              <p
                style={{
                  textAlign: 'center',
                  border: '3px solid #6DCEF2',
                  borderRadius: '10%',
                  fontFamily: 'Noto Kufi Arabic, sans-serif',
                  padding: '0.5rem',
                }}
                sx={{
                  width: 'clamp(50px, 8vw, 80px)', // Adjusts width based on screen size
                  height: 'clamp(40px, 5vw, 60px)', // Adjusts height based on screen size
                  fontSize: 'clamp(0.8rem, 2vw, 2rem)', // Responsive font size
                }}
              >
                {text}
              </p>
              {index < 3 && (
                <p
                  style={{
                    fontWeight: 'bold',
                    color: '#6DCEF2',
                    margin: 0,
                  }}
                  sx={{
                    fontSize: 'clamp(1rem, 1.5vw, 1.5rem)', // Responsive dash font size
                  }}
                >
                  &#x2013;&#x2013;&#x2013;&#x2013;&#x2013;&#x2013; {/* Dashes in a single line for better scaling */}
                </p>
              )}
            </React.Fragment>
          ))}
        </div>
      </Grid>
    </Grid>
  );
}

export default AboutRakz;
