import { Box, Card, CardContent } from '@mui/material';
import React from 'react';
import { Arrow2Down } from './images';

function GamePrompt({ onSelect }) {
  return (
    <Card
      sx={{
        backgroundImage: 'linear-gradient(315deg, #6fceed 0%, #dde84a 74%)',
        padding: '1rem',
        color: 'white',
        textAlign: 'center',
        borderRadius: '20px',
      }}
    >
      <CardContent
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        {/* Create Game */}
        <p
          style={{
            fontWeight: 'bold',
            fontFamily: 'Noto Kufi Arabic, sans-serif',
            fontSize: '4rem',
            color: 'black',
            margin: '0', // Remove default margins to reduce gap
            padding: '5px 0', // Optional padding adjustment
          }}
        >
          إنشاء لعبة
        </p>
        <p
          style={{
            fontWeight: 'bold',
            fontFamily: 'Noto Kufi Arabic, sans-serif',
            fontSize: '2rem',
            color: 'black',
            margin: '0', // Remove default margins to reduce gap
            padding: '5px 0', // Optional padding adjustment
          }}
        >
          لعبة جماعية تفاعلية نختبر فيها معرفتكم و ثقافتكم
        </p>
        <br />
        <p
          style={{
            // fontWeight: 'bold',
            fontFamily: 'Noto Kufi Arabic, sans-serif',
            fontSize: '2rem',
            color: 'black',
            width: '60%',
            margin: '0', // Remove default margins to reduce gap
            padding: '5px 0', // Optional padding adjustment
          }}
        >
          لانشاء لعبة جديدة اضغط على ( لعبة جديدة ) و لاسترجاع الألعاب السابقة، اضغط على ( ألعابي ){' '}
        </p>
        <Box
          sx={{
            display: 'flex',
          }}
        >
          <button
            style={{
              background: 'black',
              border: 'none',
              padding: '10px 20px', // Adjust padding for height and width
              color: 'yellow',
              fontFamily: 'Noto Kufi Arabic',
              fontSize: '1rem',
              cursor: 'pointer',
              borderRadius: '30px', // Adjust border radius for the button
              width: '150px', // Adjust width
              height: '50px', // Adjust height
            }}
            onClick={() => onSelect('create')}
          >
            لعبة جديدة
          </button>
          &nbsp;&nbsp;
          <button
            style={{
              background: 'yellow',
              border: 'none',
              borderRadius: '30px', // Adjust border radius for the button
              padding: '10px 20px', // Adjust padding for height and width
              color: 'black',
              fontFamily: 'Noto Kufi Arabic',
              fontSize: '1rem',
              cursor: 'pointer',
              width: '150px', // Adjust width
              height: '50px', // Adjust height
            }}
            onClick={() => onSelect('myGames')}
          >
            ألعابي
          </button>
          <br />
          <br />
        </Box>
        <img src={Arrow2Down} alt="arrow" style={{ width: '50px', height: '50px' }} />
      </CardContent>
    </Card>
  );
}

export default GamePrompt;
