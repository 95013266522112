import { Button, Card, CardContent, Grid } from '@mui/material';
import React from 'react';
import { KnowledgeTestImage } from './images';

function KnowledgeTest() {
  return (
    <Card
      sx={{
        backgroundColor: '#6DCEF2',
        color: '#000',
        padding: '16px',
        maxWidth: '100%',
        margin: '0 auto',
        borderRadius: '25px',
      }}
    >
      <CardContent>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={6}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                height: '100%',
              }}
            >
              <h1
                style={{
                  fontSize: '2.5rem',
                  fontWeight: 'bold',
                  fontFamily: 'Noto Kufi Arabic, sans-serif',
                  textAlign: 'right', // Align text to the right
                }}
              >
                اختبر معلوماتك
              </h1>
              <p
                style={{
                  fontSize: '2rem',
                  fontFamily: 'Noto Kufi Arabic, sans-serif',
                  textAlign: 'right', // Align text to the right
                  //   maxWidth: '600px',
                  margin: '10 auto',
                }}
              >
                هي لعبة ثقافية ممتعة مناسبة لجميع الاعمار فيها تختبر معلومات يمعتكم، اللعبة تشمل جميع انواع الاسئلة حسب
                الفئة المختارة.
              </p>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: 'yellow',
                  width: '200px',
                  color: '#000',
                  fontFamily: 'Noto Kufi Arabic, sans-serif',
                  fontSize: '1rem',
                  alignSelf: 'flex-end', // Align button to the right
                  borderRadius: '25px',
                  marginTop: '10px',
                }}
              >
                إنشاء لعبة
              </Button>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '50px 0',
            }}
          >
            <img
              src={KnowledgeTestImage}
              alt="Knowledge-Test"
              style={{
                width: '100%',
                maxWidth: '320px',
                height: 'auto',
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default KnowledgeTest;
