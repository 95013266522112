import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { call_icon, explore_icon, hand_raise_icon } from '../home/icons/inex';
import { Get, Post } from 'src/actions';
import { API_URLS } from 'src/constants/API_URLS';
import { useSnackbar } from 'notistack';
import { GameLayout } from 'src/layout';

function Result() {
  const navigate = useNavigate();
  const location = useLocation();
  const { question, userCateogory, allCategories, game_name } = location?.state || {}; // Retrieve the state
  const userId = localStorage.getItem('userId');
  const { enqueueSnackbar } = useSnackbar();
  const resultModel = {
    team: null,
    gameId: question?.id,
    categoryId: userCateogory?.category?.id,
    userId: userId,
    userCategoryId: userCateogory?.id,
    score: question?.score,
  };

  const handleAddResults = (team) => {
    try {
      resultModel.team = team;
      Post(
        resultModel,
        API_URLS.CREATE_USER_CATEGORY_RESULTS,
        (resp) => {
          navigate('/team-score', { state: { userCategories: allCategories, game_name: game_name } });
        },
        (error) => {
          navigate(/question/, { state: { question: question, userCateogory: userCateogory, game_name: game_name } });
        }
      );
    } catch (error) {
      enqueueSnackbar('Cannot Update Results', { variant: 'error' });
    }
  };

  const handleGetResults = () => {
    try {
      Get(
        {},
        API_URLS.GET_WINNER.replace(':id', allCategories[0]?.id),
        (resp) => {
          navigate('/congrats', { state: { result: resp?.data, allCategories: allCategories, game_name: game_name } });
        },
        (error) => {}
      );
    } catch (error) {}
  };

  return (
    <div
      style={{
        overflowX: 'hidden',
        // background: '#f9f9f9',
        height: '100%',
      }}
    >
      <GameLayout
        gameName={game_name}
        teamname={allCategories[0]?.team1_name}
        onBackToDashboard={() => {
          navigate('/team-score', { state: { userCategories: allCategories, game_name: game_name } });
        }}
        onExit={() => {
          navigate('/my-games');
        }}
        onGameOver={() => {
          handleGetResults();
        }}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} md={9} lg={10}>
          <Card
            sx={{
              width: '95%',
              height: '98%', // Make the card height responsive
              margin: 'auto',
            }}
          >
            <CardContent>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: { xs: '10px', sm: '20px' }, // Responsive padding
                  textAlign: 'center', // Center text
                  width: '100%', // Full width
                }}
              >
                <p
                  style={{
                    fontFamily: 'Noto Kufi Arabic, sans-serif',
                    fontSize: '3rem',
                  }}
                >
                  منو جاوب صح؟
                </p>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '10px',
                    width: '100%',
                    justifyContent: 'center', // Center buttons
                  }}
                >
                  <button
                    style={{
                      background: '#000000',
                      width: '100%', // Full width on small screens
                      maxWidth: '200px', // Max width on larger screens
                      borderRadius: '5px',
                      padding: '10px',
                      color: '#FFFFFF',
                      fontFamily: 'Cairo, sans-serif',
                      fontSize: '1.25rem',
                    }}
                    onClick={() => handleAddResults(userCateogory?.team1_name)}
                  >
                    {userCateogory?.team1_name}
                  </button>
                  <button
                    style={{
                      background: '#000000',
                      width: '100%', // Full width on small screens
                      maxWidth: '200px', // Max width on larger screens
                      borderRadius: '5px',
                      padding: '10px',
                      color: '#FFFFFF',
                      fontFamily: 'Cairo, sans-serif',
                      fontSize: '1.25rem',
                    }}
                    onClick={() => handleAddResults(userCateogory?.team2_name)}
                  >
                    {userCateogory?.team2_name}
                  </button>
                </Box>
                <button
                  style={{
                    marginTop: '20px', // Add margin to separate from above buttons
                    background: '#808080',
                    width: '100%', // Full width on small screens
                    maxWidth: '410px', // Max width on larger screens
                    borderRadius: '5px',
                    padding: '10px',
                    color: '#FFFFFF',
                    fontFamily: 'Noto Kufi Arabic, sans-serif',
                    fontSize: '1.25rem',
                  }}
                  onClick={() => handleAddResults('none')}
                >
                  ولا أحد
                </button>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={3} lg={2}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              width: '90%',
              height: '100%', // Make the card height responsive
              margin: 'auto',
              // gap: '1rem',
            }}
          >
            <Typography
              sx={{
                fontSize: '27px',
                fontFamily: 'Cairo',
                backgroundColor: '#000000',
                // padding: '1rem',
                borderRadius: '30px',
                color: '#ffffff',
                textAlign: 'center',
                width: '90%',
                margin: 'auto',
              }}
            >
              {userCateogory?.team1_name}
            </Typography>
            <Typography
              sx={{
                fontFamily: 'Noto Kufi Arabic, sans-serif',
                fontWeight: 'bold',
                fontSize: { xs: '1rem', sm: '2rem' }, // Responsive font size
                padding: { xs: '1rem', sm: '2rem' }, // Responsive padding
                textAlign: 'center',
                color: '#000000',
                borderRadius: '30px',
              }}
            >
              وسائل مساعدة
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '1rem',
                width: '90%',
                margin: 'auto',
                marginBottom: '1rem',
              }}
            >
              <img
                src={hand_raise_icon}
                alt="hand_raise_icon"
                height={60}
                width={60}
                style={{
                  border: '1px solid black',
                  borderRadius: '15%',
                }}
              />
              <img
                src={call_icon}
                alt="call_icon"
                height={60}
                width={60}
                style={{
                  border: '1px solid black',
                  borderRadius: '15%',
                }}
              />
              <img
                src={explore_icon}
                alt="explore_icon"
                height={60}
                width={60}
                style={{
                  border: '1px solid black',
                  borderRadius: '15%',
                }}
              />
            </Box>
            <Typography
              sx={{
                fontSize: '27px',
                fontFamily: 'Cairo',
                backgroundColor: '#000000',
                // padding: '1rem',
                borderRadius: '30px',
                color: '#ffffff',
                textAlign: 'center',
                width: '90%',
                margin: 'auto',
              }}
            >
              {userCateogory?.team2_name}
            </Typography>
            <Typography
              sx={{
                fontFamily: 'Noto Kufi Arabic, sans-serif',
                fontWeight: 'bold',
                fontSize: { xs: '1rem', sm: '2rem' }, // Responsive font size
                padding: { xs: '1rem', sm: '2rem' }, // Responsive padding
                textAlign: 'center',
                color: '#000000',
                borderRadius: '30px',
              }}
            >
              وسائل مساعدة
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '1rem',
              }}
            >
              <img
                src={hand_raise_icon}
                alt="hand_raise_icon"
                height={60}
                width={60}
                style={{
                  border: '1px solid black',
                  borderRadius: '15%',
                }}
              />
              <img
                src={call_icon}
                alt="call_icon"
                height={60}
                width={60}
                style={{
                  border: '1px solid black',
                  borderRadius: '15%',
                }}
              />
              <img
                src={explore_icon}
                alt="explore_icon"
                height={60}
                width={60}
                style={{
                  border: '1px solid black',
                  borderRadius: '15%',
                }}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default Result;